import { $themeColors } from '@themeConfig'
// colors
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  chartone: '#1B9AAA',
  charttwo: '#CCE6F4',
  labelColor:'#6e6b7b',
  SecondaryColor: '#00C49A',
  ThiredColor: '#CCE6F4',
  black: '#000000',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  
  lineChart: {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: false,
      hover: {
        mode: 'label',
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.white,
        backgroundColor: $themeColors.white,
        titleFontColor: $themeColors.white,
        bodyFontColor: $themeColors.white,
      },
      layout: {
        padding: {
          top: -15,
          bottom: -25,
          left: -15,
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
            },
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
            },
            ticks: {
              stepSize: 10,
              min: 0,
              max: 40,
              fontColor: chartColors.labelColor,
            },
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
          },
        ],
      },
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          
          padding: 25,
          boxWidth: 9,
        },
      },
    },
    data: {
      labels: ['Mon', 'Tue', 'Wed', 'Thu','Fri', 'Sat', 'Sun' ],
      datasets: [
        {
          data: [5,18,14,25,20,23,33],
          label: 'Salaried Staff Shifts',
          borderColor: chartColors.chartone,
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: chartColors.black,
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: $themeColors.white,
          pointHoverBackgroundColor: chartColors.lineChartPrimary,
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: chartColors.tooltipShadow,
        },
        {
          data: [5,5,9,7,13,10,15],
          label: 'Locum Shifts',
          borderColor: chartColors.charttwo,
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: chartColors.charttwo,
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: $themeColors.white,
          pointHoverBackgroundColor: chartColors.lineChartPrimary,
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: chartColors.tooltipShadow,
        },
       
      ],
    },
  },
  bubbleChart: {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              stepSize: 10,
              min: 0,
              max: 100,
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              stepSize: 10,
              min: 0,
              max: 50,
              fontColor: chartColors.labelColor,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
    },
    data: {
      animation: {
        duration: 10000,
      },
      datasets: [
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 10,
              y: 22,
              r: 10,
            },
          ],
        },
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 20,
              y: 25,
              r: 15,
            },
          ],
        },
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 30,
              y: 11,
              r: 7,
            },
          ],
        },
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 40,
              y: 35,
              r: 20,
            },
          ],
        },
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 60,
              y: 39,
              r: 25,
            },
          ],
        },
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 60,
              y: 20,
              r: 10,
            },
          ],
        },
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 70,
              y: 38,
              r: 25,
            },
          ],
        },
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 80,
              y: 19,
              r: 8,
            },
          ],
        },
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 90,
              y: 30,
              r: 15,
            },
          ],
        },
        {
          backgroundColor: chartColors.SecondaryColor,
          borderColor: chartColors.SecondaryColor,
          data: [
            {
              x: 100,
              y: 16,
              r: 8,
            },
          ],
        },

        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 10,
              y: 9,
              r: 5,
            },
          ],
        },
        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 20,
              y: 6,
              r: 4,
            },
          ],
        },
        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 30,
              y: 22,
              r: 5,
            },
          ],
        },
        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 40,
              y: 14,
              r: 8,
            },
          ],
        },
        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 50,
              y: 4,
              r: 4,
            },
          ],
        },
        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 60,
              y: 13,
              r: 9,
            },
          ],
        },
        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 70,
              y: 29,
              r: 18,
            },
          ],
        },
        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 80,
              y: 10,
              r: 24,
            },
          ],
        },
        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 90,
              y: 18,
              r: 8,
            },
          ],
        },
        {
          backgroundColor: chartColors.ThiredColor,
          borderColor: chartColors.ThiredColor,
          data: [
            {
              x: 100,
              y: 6,
              r: 6,
            },
          ],
        },
      ],
    },
  },
}
