<template>
  <b-card no-body>
      <b-card-header class="">
       <div class="d-flex align-items-center ">

                <div>
                  <h3 class="mb-0 font-weight-bolder "> Role Breakdown </h3>
                </div>
                </div>

                <div class="ml-auto ">
                 <b-row>
                   <div class="ml-1">
                      <b-avatar variant="success" rounded size="10"  >
                        <feather-icon icon="CheckIcon" size="1" />
                    </b-avatar>
                    </div>
                   <div class="ml-1">
                     <small>Salaried Staff</small>
                    </div>
                    <div class="ml-1">
                      <b-avatar variant="info" rounded size="10"  >
                        <feather-icon icon="CheckIcon" size="1" />
                    </b-avatar>
                    </div>
                   <div class="ml-1">
                     <small>Locums</small>
                    </div>
                 </b-row>
                </div>
      <b-card-text class="font-medium-5 mb-0 ml-3">

        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bubble-chart
        :key="custom_key"
        :height="400"
        :data="chartjsData.bubbleChart.data"
        :options="chartjsData.bubbleChart.options"
        :plugins="plugins"

      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BBadge, BAvatar, BCol, BRow, BCardText,
} from 'bootstrap-vue'
import ChartjsComponentBubbleChart from '../../chartcomponents/ChartjsComponentBubbleChart'
import chartjsData from '../../chartcomponents/chartjsData'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ChartjsComponentBubbleChart,
    BCard,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BCol,
    BRow,
    BCardText,
  },

  data() {
    return {
      custom_key : 0,
      chartjsData,
      plugins: [

        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.height = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  mounted() {
    this.custom_key++
  },
  methods: {
    changesKey () {
      this.custom_key++
    }
  },
  directives: {
    Ripple,
  },
}
</script>

