<template>
         <div class="Home">

         <!-- Header Bar -->
         
      <b-row class=" ">
               <!-- Left side -->

        <b-col cols="12"  xl="7" />
         
            <!-- Right side Downloading button -->

        <b-col cols="12"  xl="5" class="pt-0 pb-2 pt-xl-2 pb-xl-0">
            <b-row class="">

                <!-- 03 WEEK/MONTH/YEAR -->

                <b-col cols="12" md="7" xl="7" class="text-right mb-1">
                     <div>
                          <b-button-group class="bg-white">
                             <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                                    Week
                             </b-button>
                             <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary">
                                    Month
                             </b-button>
                             <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary">
                                    Year
                            </b-button>
                          </b-button-group>
                     </div>
                </b-col>

                <!-- 04  This week -->
               
                <b-col cols="12" md="5" xl="5">
                     <b-form-group>
                          <v-select
                            style="width: 210px;"
                            class="bg-white"
                            placeholder="This Week: Feb 22 - Feb 28"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title3"
                            :options="option3"
                          />
                     </b-form-group>
                 </b-col>
             </b-row>
        </b-col>
      </b-row>
  

         <!-- Hours and Shifts / Staff Tabs-->

            <b-tabs >
                <b-tab title="Hours and Shifts">
                    <div class="Alert-body mt-n1 "><Hours /></div>
                </b-tab>
                <b-tab title="Staff" @click="$refs.staff.changesKey()">
                    <div class="Alert-body mt-n1"><Staff ref="staff"/></div>
                </b-tab>
            </b-tabs>

    </div>

</template>

<script>
import Hours from './hours/index'
import Staff from './staff/index'
import Ripple from 'vue-ripple-directive'
import { BCard, BTab, BTabs, BAvatar, BBadge, BButtonGroup, BButton, BCol, BLink, BRow, BFormGroup } from 'bootstrap-vue'

export default {
    name:'Home',
    components:{
        Hours,
        Staff,
        BTab,
        BTabs,
        BAvatar, 
        BBadge, 
        BButtonGroup, 
        BButton, 
        BCard, 
        BCol, 
        BLink, 
        BRow, 
        BFormGroup
    },
directives: {
    Ripple,
  },
  data() {
       
    return { 

       option1: [{title1: 'Programmes 01'},{title1: 'Programmes 02'},{title1: 'Programmes 03'}],

       option2: [ {title2:'United Kingdom'},{title2:'United States'},{title2:'Sri Lanka'},],

       option3: [{title3: 'Feb 15 - Feb 21'},{title3: 'Feb 8 - Feb 2'},{title3: 'Feb 1 - Feb 7'},{title3: 'Jan 25 - Jan 31'},],

    }
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>